import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout title="Not Found">
    <SEO title="404: Not found" />
    <h2 className="subtitle">
      The page you were looking for could not be found.
      <br></br>
      Please check the URL or contact the site administrators.
    </h2>
  </Layout>
)

export default NotFoundPage
